import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	Errors,
	FieldDescription,
	FileInput,
	Form,
	Heading,
	Picture,
	Section,
	TextField,
} from '@troon/ui';
import { cachedGet, clientAction } from '@troon/api-client';
import { action, createAsync, useAction, useSubmission } from '@solidjs/router';
import { createEffect, createSignal, createUniqueId, For, Match, Show, Suspense, Switch } from 'solid-js';
import { IconCloseCircle } from '@troon/icons/close-circle';
import { useCurrentFacility } from '../../../../../providers/root';
import { Content } from '../../../../../components/main-content';
import { resize } from '../../../../../modules/image-resize';
import type { StartUploadMediaBodyDto } from '@troon/api-client';
import type { z } from 'zod';

export default function MediaSettings() {
	const facility = useCurrentFacility();
	const media = createAsync(() => getMedia({ path: { facilityId: facility()!.facility.slug } }));

	const [showLogoDialog, setShowLogoDialog] = createSignal(false);
	const [showHeroDialog, setShowHeroDialog] = createSignal(false);
	const [showGalleryDialog, setShowGalleryDialog] = createSignal(false);

	return (
		<Content>
			<Heading as="h1">Media</Heading>
			<Section appearance="contained">
				<Heading as="h2">Logo</Heading>
				<p>Your logo will display along with your Facility's name, courses, and location.</p>
				<Suspense>
					<Show
						when={media()?.logo}
						fallback={
							<UploadImage facilitySlug={facility()!.facility.slug} type="LOGO" minWidth={400} maxWidth={1600} />
						}
					>
						{(logo) => (
							<>
								<span class="size-32 shrink-0 overflow-hidden rounded-md border border-neutral p-1">
									<Picture
										src={logo().url}
										sizes="200px"
										alt=""
										width={200}
										height={200}
										crop="center"
										mode="contain"
										class="w-full"
									/>
								</span>
								<span class="max-w-xl">
									<Dialog key="upload-logo" open={showLogoDialog()} onOpenChange={setShowLogoDialog}>
										<DialogTrigger appearance="tertiary">Replace</DialogTrigger>
										<DialogContent header="Replace logo" headerLevel="h3">
											<UploadImage
												facilitySlug={facility()!.facility.slug}
												type="LOGO"
												minWidth={400}
												maxWidth={1600}
												onComplete={() => setShowLogoDialog(false)}
											/>
										</DialogContent>
									</Dialog>
								</span>
							</>
						)}
					</Show>
				</Suspense>
			</Section>

			<Section appearance="contained">
				<Heading as="h2">Hero</Heading>
				<p>
					Choose a dynamic and memorable photo to be displayed with your Facility across the Troon booking experience.
				</p>
				<Suspense>
					<Show
						when={media()?.hero}
						fallback={
							<div class="max-w-xl">
								<UploadImage facilitySlug={facility()!.facility.slug} type="HERO" minWidth={1000} maxWidth={3480} />
							</div>
						}
					>
						{(hero) => (
							<>
								<Picture
									src={hero().url}
									sizes="90vw"
									alt=""
									width={1280}
									height={388}
									class="w-full rounded"
									crop="entropy"
								/>
								<div class="max-w-xl">
									<Dialog key="upload-hero" open={showHeroDialog()} onOpenChange={setShowHeroDialog}>
										<DialogTrigger appearance="tertiary">Replace</DialogTrigger>
										<DialogContent header="Replace hero photo" headerLevel="h3">
											<UploadImage
												facilitySlug={facility()!.facility.slug}
												type="HERO"
												minWidth={1000}
												maxWidth={3480}
												onComplete={() => setShowHeroDialog(false)}
											/>
										</DialogContent>
									</Dialog>
								</div>
							</>
						)}
					</Show>
				</Suspense>
			</Section>

			<Section appearance="contained">
				<Heading as="h2">Gallery Photos</Heading>
				<p>Select photos to appear on your Facility's main listing page to complement the Hero photo.</p>
				<Suspense>
					<div class="grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
						<For each={media()?.gallery}>
							{(photo) => (
								<div class="group relative">
									<Picture
										src={photo.url}
										sizes="50vw"
										alt=""
										width={512}
										height={320}
										class="w-full rounded group-hover:brightness-50"
										crop="entropy"
									/>
									<div class="absolute inset-0 hidden items-center justify-center group-hover:flex">
										<DeletePhoto id={photo.id} facilityId={facility()!.facility.slug} />
									</div>
								</div>
							)}
						</For>
					</div>

					<div class="max-w-xl">
						<Dialog key="upload-gallery" open={showGalleryDialog()} onOpenChange={setShowGalleryDialog}>
							<DialogTrigger appearance="tertiary">Add Photo</DialogTrigger>
							<DialogContent header="Add Photo" headerLevel="h3">
								<UploadImage
									facilitySlug={facility()!.facility.slug}
									type="GALLERY"
									minWidth={1000}
									maxWidth={3480}
									onComplete={() => setShowGalleryDialog(false)}
								/>
							</DialogContent>
						</Dialog>
					</div>
				</Suspense>
			</Section>
		</Content>
	);
}

const getMedia = cachedGet('/v0/operator/facilities/{facilityId}/media');

type UploadProps = {
	facilitySlug: string;
	type: z.infer<typeof StartUploadMediaBodyDto>['type'];
	minWidth?: number;
	maxWidth: number;
	onComplete?: () => void;
};

function UploadImage(props: UploadProps) {
	const [extension, setExtension] = createSignal<string | undefined>();
	const [blob, setBlob] = createSignal<Blob | undefined>();
	// TODO: add a real progressbar
	// const [progress, setProgress] = createSignal<number>();
	const id = createUniqueId();
	const submission = useSubmission(createMedia, ([data]: [FormData]) => data.get('__formId') === id);
	const upload = useAction(uploadMedia);
	const uploadSubmission = useSubmission(uploadMedia, ([data]: [UploadData]) => data.id === id);
	const finalize = useAction(finalizeMedia);
	const finalizeSubmission = useSubmission(finalizeMedia, ([data]: [FormData]) => data.get('__formId') === id);
	const [error, setError] = createSignal<string | undefined>();

	async function handleUpload(mediaId: string, url: string, blob: Blob) {
		await upload({
			id,
			url,
			blob,
			onProgress: (e: ProgressEvent) => {
				if (e.lengthComputable) {
					// TODO: add a real progressbar
					// setProgress(e.loaded / e.total);
				}
			},
		});

		const data = new FormData();
		data.set('__facilityId', props.facilitySlug);
		data.set('__mediaId', mediaId);
		data.set('__formId', id);
		await finalize(data);
		submission.clear();
		uploadSubmission.clear();
	}

	createEffect(() => {
		const url = submission.result?.uploadUrl;
		const imgBlob = blob();
		if (url && imgBlob && !uploadSubmission.pending && !uploadSubmission.result && !uploadSubmission.error) {
			handleUpload(submission.result!.mediaId, url, imgBlob);
		}
	});

	return (
		<Switch>
			<Match when={finalizeSubmission.result}>
				<div class="flex w-full flex-col gap-4">
					<p aria-live="polite">Upload complete!</p>
					<Button
						appearance="primary"
						onClick={() => {
							finalizeSubmission.clear();
							props.onComplete && props.onComplete();
						}}
					>
						Done
					</Button>
				</div>
			</Match>
			<Match when={uploadSubmission.pending || finalizeSubmission.pending}>
				<ActivityIndicator>Uploading…</ActivityIndicator>
			</Match>
			<Match when={!submission.result}>
				<Form action={createMedia} id={id}>
					<input type="hidden" name="__facilityId" value={props.facilitySlug} />
					<input type="hidden" name="type" value={props.type} />
					<input type="hidden" name="ext" value={extension()} />
					<TextField name="__logo">
						<FileInput
							accept="image/png, image/jpeg, image/jpg"
							onInput={(e) => {
								setError(undefined);
								const files = e.target.files;
								if (!files || !files.length) {
									setExtension(undefined);
									return;
								}
								const file = files[0]!;
								setExtension(file.type.replace('image/', ''));
								// eslint-disable-next-line solid/reactivity
								resize(file, { maxWidth: props.maxWidth }).then((res) => {
									if (res.width < (props.minWidth ?? 0)) {
										setError(
											`The selected image is only ${res.width}pixels wide, but must be at least ${props.minWidth}pixels.`,
										);
										return;
									}
									setBlob(res.blob);
								});
							}}
						/>
						<Show when={error()}>
							{(error) => (
								<p class="text-red-500" aria-live="assertive">
									{error()}
								</p>
							)}
						</Show>
						<FieldDescription>Image must be at least {props.minWidth}pixels wide.</FieldDescription>
					</TextField>

					<Button type="submit" disabled={!blob()}>
						Upload
					</Button>
				</Form>
			</Match>
		</Switch>
	);
}

const createMedia = clientAction('POST', '/v0/operator/facilities/{facilityId}/media', (data) => ({
	params: { path: { facilityId: data.get('__facilityId') as string } },
}));

const finalizeMedia = clientAction(
	'PUT',
	'/v0/operator/facilities/{facilityId}/media/{mediaId}',
	(data) => ({
		params: { path: { facilityId: data.get('__facilityId') as string, mediaId: data.get('__mediaId') as string } },
	}),
	{
		revalidate: ['/v0/operator/facilities/{facilityId}/media', '/v0/operator/facilities'],
	},
);

type UploadData = {
	id: string;
	url: string;
	blob: Blob;
	onProgress: (event: ProgressEvent) => void;
};

const uploadMedia = action(async (data: UploadData) => {
	return new Promise((resolve) => {
		const req = new XMLHttpRequest();
		req.open('PUT', data.url);
		req.onprogress = data.onProgress;
		req.onreadystatechange = () => {
			if (req.readyState === 4) {
				resolve(req.responseText);
			}
		};
		req.send(data.blob);
	});
}, 'upload-media');

function DeletePhoto(props: { id: string; facilityId: string }) {
	const [show, setShow] = createSignal(false);
	return (
		<Dialog key="delete-gallery" open={show()} onOpenChange={setShow}>
			<DialogTrigger appearance="tertiary" class="size-fit grow-0">
				<IconCloseCircle /> Delete
			</DialogTrigger>
			<DialogContent header="Delete photo?" headerLevel="h3">
				<Form action={deletePhoto}>
					<input type="hidden" name="__facilityId" value={props.facilityId} />
					<input type="hidden" name="__mediaId" value={props.id} />

					<p>Are you sure you want to delete this transaction? This cannot be undone.</p>

					<Errors />

					<DialogActions>
						<DialogAction type="submit" action="danger">
							Delete
						</DialogAction>
						<DialogAction onClick={() => setShow(false)} appearance="secondary" type="button">
							Cancel
						</DialogAction>
					</DialogActions>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

const deletePhoto = clientAction(
	'DELETE',
	'/v0/operator/facilities/{facilityId}/media/{mediaId}',
	(data) => ({
		params: { path: { facilityId: data.get('__facilityId') as string, mediaId: data.get('__mediaId') as string } },
	}),
	{
		revalidate: ['/v0/operator/facilities/{facilityId}/media'],
	},
);
