export async function resize(file: File, { maxWidth }: { maxWidth: number }) {
	const objectUrl = URL.createObjectURL(file);
	const img = new Image();
	img.src = objectUrl;
	// initiate loading the image so it's available for processing
	await img.decode();

	const width = img.naturalWidth;
	const height = img.naturalHeight;
	const ratio = width / height;

	if (width <= maxWidth) {
		const blob = new Blob([new Uint8Array(await file.arrayBuffer())]);
		return { width, height, blob };
	}

	const canvas = new OffscreenCanvas(maxWidth, maxWidth / ratio);
	const ctx = canvas.getContext('2d')!;
	ctx.drawImage(img, 0, 0, maxWidth, maxWidth / ratio);
	const blob = await canvas.convertToBlob({ type: file.type, quality: 1 });
	return { width: maxWidth, height: maxWidth / ratio, blob };
}
